import { FlowItem } from "@/models/Configuration/FlowItem";
import { TenantBookingConfiguration } from "@/models/Configuration/TenantBookingConfiguration";
import { TreeItem } from "@/models/Configuration/TreeItem";
import _ from "lodash";
import Utils from "../models/Utils/utils";
import { computed } from "vue";
import { useStore } from "../models/Store/store";

// eslint-disable-next-line
export function useTenantConfig() {
    const store = useStore();

    const hasConfiguration = computed(() => {
        return (
            _.isNull(store.tenantConfig) === false &&
            _.isUndefined(store.tenantConfig) === false &&
            _.isNull(store.tenantConfig.configurations) === false &&
            _.isUndefined(store.tenantConfig.configurations) === false
        );
    });

    const hasCheckinOnline = computed(() => {
        if (store.tenantConfig && store.tenantConfig.configurations && store.tenantConfig.configurations.tenant) {
            const checkinOnline = store.tenantConfig.configurations.tenant.find((item) => {
                return item.varName == "tenant.checkin_type";
            });

            if (checkinOnline) return checkinOnline.value === "online";
        }
        return false;
    });

    const hasMunicipalityChoiceActive = computed(() => {
        if (store.tenantConfig && store.tenantConfig.configurations && store.tenantConfig.configurations.tenant) {
            const municipalityConfig = store.tenantConfig.configurations.tenant.find((item) => {
                return item.varName == "tenant.municipality";
            });

            if (municipalityConfig) return municipalityConfig.value == "true";
        }
        return false;
    });

    const showBtnBookPro = computed(() => {
        if (store.tenantConfig && store.tenantConfig.configurations && store.tenantConfig.configurations.tenant) {
            const hasEstateProfessional = store.tenantConfig.configurations.tenant.find((item) => {
                return item.varName == "tenant.estateprofessional";
            });

            if (hasEstateProfessional) return hasEstateProfessional.value == "true";
        }
        return false;
    });

    const skipUserChoise = computed(() => {
        if (store.tenantConfig && store.tenantConfig.configurations && store.tenantConfig.configurations.tenant) {
            const skipuserchoise = store.tenantConfig.configurations.tenant.find((item) => {
                return item.varName == "tenant.skipuserchoice";
            });

            if (skipuserchoise) return skipuserchoise.value == "true";
        }
        return false;
    });

    const privacyText = computed(() => {
        const stepsMade = store.stepsMade;
        let privacyText = "";
        if (store.tenantConfig && store.tenantConfig.privacyPolicies) {
            let privacyTextDefault = store.tenantConfig.privacyPolicies.find((item) => {
                return item.isDefault;
            });

            if (stepsMade.service != null && stepsMade.service.privacyPolicyId != null) {
                const privacyTextService = store.tenantConfig.privacyPolicies.find((item) => {
                    return item.id == stepsMade.service.privacyPolicyId;
                });
                if (privacyTextService) {
                    privacyTextDefault = privacyTextService;
                }
            }

            if (privacyTextDefault) {
                privacyText = privacyTextDefault.text;
            }
        }
        return privacyText;
    });

    const webPriorityChannel = computed(() => {
        if (hasConfiguration.value) {
            if (_.isNull(store.tenantConfig.configurations.tenant) === false && _.isUndefined(store.tenantConfig.configurations.tenant) === false) {
                const priorityChannel = store.tenantConfig.configurations.tenant.find((item) => {
                    return item.varName == "book.prioritychannel";
                });
                return priorityChannel.value as string;
            }
        }
        return "";
    });

    const hasUserDataAsForm = computed(() => {
        if (hasConfiguration.value) {
            const tenantConfigs = Utils.getGenericConfig(store.tenantConfig.configurations.tenant) as TenantBookingConfiguration;

            if (_.isNull(tenantConfigs) === false && _.isUndefined(tenantConfigs) === false) {
                return tenantConfigs.registrationUserdataForm.value as boolean;
            }
        }
        return false;
    });

    const hasUserDataAsSpid = computed(() => {
        if (hasConfiguration.value) {
            const tenantConfigs = Utils.getGenericConfig(store.tenantConfig.configurations.tenant) as TenantBookingConfiguration;

            if (_.isNull(tenantConfigs) === false && _.isUndefined(tenantConfigs) === false) {
                return tenantConfigs.registrationUserdataSpid.value as boolean;
            }
        }
        return false;
    });

    const hasUserDataAsExternalLogin = computed(() => {
        if (hasConfiguration.value) {
            const tenantConfigs = Utils.getGenericConfig(store.tenantConfig.configurations.tenant) as TenantBookingConfiguration;

            if (_.isNull(tenantConfigs) === false && _.isUndefined(tenantConfigs) === false) {
                return tenantConfigs.registrationUserdataExternal.value as boolean;
            }
        }
        return false;
    });

    const getUserDataExternalLoginLink = computed(() => {
        if (hasConfiguration.value) {
            const tenantConfigs = Utils.getGenericConfig(store.tenantConfig.configurations.tenant) as TenantBookingConfiguration;

            if (_.isNull(tenantConfigs) === false && _.isUndefined(tenantConfigs) === false) {
                return tenantConfigs.registrationUserdataExternalLink.value as string;
            }
        }
        return "";
    });

    const getWebLabel = computed(() => {
        if (hasConfiguration.value) {
            const tenantConfigs = Utils.getGenericConfig(store.tenantConfig.configurations.tenant) as TenantBookingConfiguration;

            if (_.isNull(tenantConfigs) === false && _.isUndefined(tenantConfigs) === false) {
                if (tenantConfigs.widgetWeblabels && tenantConfigs.widgetWeblabels.value) {
                    let webLabels = JSON.parse(tenantConfigs.widgetWeblabels.value as string) as [{key: string, value:string}];
                    const webLabelsObj = webLabels.reduce((acc: any, cur: {key: string, value:string}) => {
                        let kk = cur.key.replace("web.", "");
                        acc[kk] = cur.value;
                        return acc;
                    }, {});

                    return webLabelsObj;
                }
            }
        }

        return null;
    });

    const getSelectionMode = (selectionModeVarName: string) => {
        if (hasConfiguration.value && store.tenantConfig.configurations.tenant) {
            const selectionModeConfig = store.tenantConfig.configurations.tenant.find((item) => {
                return item.varName === selectionModeVarName;
            });

            if (selectionModeConfig) {
                return selectionModeConfig.value.toString();
            }
        }
        return "";
    };

    const getRouteNameLocationSelection = (): string => {
        const locationSelectionMode = getSelectionMode("locationselection.web");
        let locationRouteSelection = "booklocation";
        switch (locationSelectionMode) {
            case "buttons":
                locationRouteSelection = "frontoffice";
                break;
            case "datatable":
                locationRouteSelection = "booklocation";
                break;
        }
        return locationRouteSelection;
    };

    const getRouteNameServiceSelection = (): string => {
        const locationSelectionMode = getSelectionMode("serviceselection.web");
        let serviceRouteSelection = "bookservice";
        switch (locationSelectionMode) {
            case "buttons":
                serviceRouteSelection = "bookflow";
                break;
            case "dropdown":
                serviceRouteSelection = "bookservice";
                break;
        }
        return serviceRouteSelection;
    };

    const findNodeById = (node: TreeItem<FlowItem>, nodeId: number): TreeItem<FlowItem> => {
        if (node.item.id === nodeId) {
            return node;
        }

        if (node.children.length > 0) {
            for (let i = 0; i < node.children.length; i++) {
                const element = node.children[i];

                const _node = findNodeById(element, nodeId);
                if (_node !== null) {
                    return _node;
                }
            }
        }

        return null;
    };

    const findServiceById = (node: TreeItem<FlowItem>, serviceId: string): TreeItem<FlowItem> => {
        if (node.isLeaf && node.item.isService && node.item.service.id.toString() === serviceId) {
            return node;
        }

        if (node.children.length > 0) {
            for (let i = 0; i < node.children.length; i++) {
                const element = node.children[i];

                const _node = findServiceById(element, serviceId);
                if (_node !== null) {
                    return _node;
                }
            }
        }

        return null;
    };

    const findPathToServiceByServiceId = (tree: TreeItem<FlowItem>, targetNode: string) => {
        function buildPath(subTree: TreeItem<FlowItem>, targetNode: string, currentPath: string): string | undefined {
            if (subTree.isLeaf && subTree.item.isService && subTree.item.service.id.toString() === targetNode) {
                // add the target node to path end
                return currentPath;
            } else if (subTree.children) {
                for (let i = 0; i < subTree.children.length; i++) {
                    const node = subTree.children[i];
                    // loop all and find if the node matches the target
                    // if it doesn't match, check if it has children
                    // if children present, check the node in them ( recursion )
                    // before checking the children, add the node name to path ( to build the path name )
                    const path = buildPath(node, targetNode, currentPath + "," + node.item.id);
                    // only return(stop) the fn when there is any path ( coming from above case node.id === targetNode.id );
                    // if there is no path, means it couldn't find any thing, don't return ( stop ) because need to check the children
                    // of other nodes as well and if we return the loop will also stop.
                    if (path) return path;
                }
            }
        }

        const path = buildPath(tree, targetNode, "");
        if (!path) {
            return "";
        }
        return path
            .split(",")
            .filter((id) => id.trim())
            .join(",");
    };

    const findPathToServiceByServiceCmsId = (tree: TreeItem<FlowItem>, targetNode: string) => {
        function buildPath(subTree: TreeItem<FlowItem>, targetNode: string, currentPath: string): string | undefined {
            if (subTree.isLeaf && subTree.item.isService && subTree.item.service.cmsId && subTree.item.service.cmsId.toString() === targetNode) {
                // add the target node to path end
                return currentPath;
            } else if (subTree.children) {
                for (let i = 0; i < subTree.children.length; i++) {
                    const node = subTree.children[i];
                    // loop all and find if the node matches the target
                    // if it doesn't match, check if it has children
                    // if children present, check the node in them ( recursion )
                    // before checking the children, add the node name to path ( to build the path name )
                    const path = buildPath(node, targetNode, currentPath + "," + node.item.id);
                    // only return(stop) the fn when there is any path ( coming from above case node.id === targetNode.id );
                    // if there is no path, means it couldn't find any thing, don't return ( stop ) because need to check the children
                    // of other nodes as well and if we return the loop will also stop.
                    if (path) return path;
                }
            }
        }

        const path = buildPath(tree, targetNode, "");
        if (!path) {
            return "";
        }
        return path
            .split(",")
            .filter((id) => id.trim())
            .join(",");
    };

    const findNodeByIdFromNodeArray = (nodes: TreeItem<FlowItem>[], nodeId: number): TreeItem<FlowItem> => {
        for (let j = 0; j < nodes.length; j++) {
            const _node = findNodeById(nodes[j], nodeId);
            if (_node !== null) {
                return _node;
            }
        }
        return null;
    };

    const getNodeRelatedTo = (node: TreeItem<FlowItem>): FlowItem[] => {
        const childrenNodes: FlowItem[] = [];

        for (let i = 0; i < node.children.length; i++) {
            const child = node.children[i];
            childrenNodes.push(child.item);
        }

        return childrenNodes;
    };

    const getImageSourceOfNode = (node: TreeItem<FlowItem>): string => {
        let imageSrc = "";

        if (node) {
            if (node.isLeaf) {
                if (node.item.service && node.item.service.imageUrl) {
                    imageSrc = node.item.service.imageUrl;
                }
            } else {
                if (node.item.image) {
                    imageSrc = node.item.image;
                }
            }
        }
        return imageSrc;
    };

    const getWebDelayDaysForLocation = (locationId: string): number => {
        const stepsMade = store.stepsMade;
        if (_.isNull(stepsMade) === false && _.isNull(stepsMade.location) === false) {
            if (hasConfiguration.value) {
                const tenantConfigs = Utils.getGenericConfig(store.tenantConfig.configurations.tenant) as TenantBookingConfiguration;

                if (_.isNull(tenantConfigs) === false && _.isUndefined(tenantConfigs) === false) {
                    try {
                        const delayDaysArray: string[] = (tenantConfigs.bookingdelayWeb.value as string).split("|");

                        if (delayDaysArray.length > 0) {

                            for(var i = 0; i < delayDaysArray.length; i++) {
                                const locDetail = delayDaysArray[i].split(".");
                                if (locDetail[0] === locationId) {
                                    return parseInt(locDetail[1], 10);
                                }
                            }
                        }
                    } catch {
                        console.log("Mancata configurazione giorni ritardo");
                    }
                }
            }
        }
        return 0;
    };

    return {
        hasConfiguration,
        hasCheckinOnline,
        hasMunicipalityChoiceActive,
        hasUserDataAsForm,
        hasUserDataAsSpid,
        hasUserDataAsExternalLogin,
        getUserDataExternalLoginLink,
        showBtnBookPro,
        privacyText,
        webPriorityChannel,
        getWebLabel,
        skipUserChoise,
        findNodeById,
        findServiceById,
        findPathToServiceByServiceId,
        findPathToServiceByServiceCmsId,
        findNodeByIdFromNodeArray,
        getNodeRelatedTo,
        getImageSourceOfNode,
        getSelectionMode,
        getRouteNameLocationSelection,
        getRouteNameServiceSelection,
        getWebDelayDaysForLocation        
    };
}
